/**
 * Typeahead members table result module
 * This helps multiple pages/views display invited members in a table format
 */

// var Backbone = require('backbone');
import $ from 'jquery';
import notif from 'components/notification';
import modal from 'components/modal';

// Handlebars templates
import collaboratorTpl from 'templates/shared/typeahead/collaborator-result.hbs';
import organizationTpl from 'templates/shared/typeahead/organization-result.hbs';
import addedTpl from 'templates/shared/typeahead/user-added.hbs';
import avatarTpl from 'templates/shared/avatars.hbs';

export default {
	// POST to server
	postData(e, params, url, typeaheadType) {
		if (typeaheadType) {
			this.typeaheadType = typeaheadType;
		}

		$.ajax({
			headers: { 'X-CSRF-Token': FLYWHEEL_CSRF_TOKEN },
			method: 'post',
			url,
			data: params,
			dataType: 'json',
			error: () => {
				// Remove loading class
				$('.typeahead__results').removeClass('loading');

				modal.close();

				// Show error message
				notif.show({
					message: "We're sorry, something went wrong.",
					type: 'error'
				});
			},
			success: (result) => {
				let $parent = null;
				let dataObject = {};
				let avatarObject = {};

				if (result.status === 'error') {
					// Remove loading class
					$('.typeahead__results').removeClass('loading');

					// Set this differently for email results so we can show "already invited" if required
					if (typeof params.email !== 'undefined') {
						params.user_id = params.email;
					}

					if (result.invite_limit_reached) {
						location.reload();
						return;
					}

					$parent = $(`.typeahead__results [data-id="${params.user_id}"]`).parent('li');
					$parent.addClass('--already-invited');

					// Remove after a few seconds
					setTimeout(() => {
						$parent.removeClass('--already-invited');
					}, 2000);
				} else {
					dataObject = {};
					avatarObject = {
						dimensions: 41
					};

					// Remove loading class & close typeahead results
					$('.typeahead__results').removeClass('loading open');

					// If it's a user or org
					if (typeof result.object !== 'undefined' || typeof result.to_user !== 'undefined') {
						// Set the URL for removing it
						if (typeof result.object !== 'undefined') {
							dataObject = result.object;
							dataObject.removeUrl = result.object.remove_url;
						}

						dataObject.user = false;
						dataObject.org = false;

						avatarObject.account = {
							avatar_fallback_color: result.avatar_fallback_color,
							initials: result.object.initials,
							avatar: {
								large_url: result.avatar.large_url
							},
							name: result.object.name
						};

						// Set the type boolean
						if (result.type === 'User') {
							dataObject.user = true;
						} else if (result.type === 'Organization') {
							dataObject.org = true;
						}
					} else {
						// If it's an email
						dataObject = result;
						dataObject.user = true;
						dataObject.isEmail = true;

						dataObject.type = 'email';
						dataObject.name = result.email;

						// Set the URL for resending it
						dataObject.resendUrl = result.resend_url;

						// Set the URL for revoking it
						dataObject.revokeUrl = result.revoke_url;

						avatarObject.account = {
							avatar_fallback_color: 'purple',
							initials: result.email.substring(0, 2),
							avatar: {
								large_url: '/assets/images/app/avatars/user-spacer.png'
							}
						};
					}

					this.addToTable(dataObject, avatarObject);
					this.addToList(dataObject, avatarObject);
				}
			}
		});
	},

	// Add user to main table
	addToTable(account, avatar) {
		const template = this.typeaheadType === 'organizations' ? organizationTpl(account) : collaboratorTpl(account);

		let avatarTemplate;

		if (account.type === 'email') {
			avatarTemplate = avatarTpl({
				size: 'small',
				letter: account.email.substring(0, 1),
				account: avatar.account
			});
		} else {
			avatarTemplate = avatarTpl({
				size: 'small',
				letter: account.initials.substring(0, 1),
				account: avatar.account
			});
		}

		if (this.typeaheadType === 'organizations') {
			$('#members-list').append(template);

			// Then, insert the avatar
			$(avatarTemplate).insertBefore('#members-list tr:last-child .org-info');
		} else {
			// Insert the main template first
			$('#collaborators-list').append(template);

			// Then, insert the avatar
			$('#collaborators-list').find('.collaborator_user:last-child .slat__inner').prepend(avatarTemplate);
		}
	},

	// Add user to list shown after typeahead
	addToList(account, avatar) {
		let avatarTemplate = null;
		let template = null;

		if (account.type === 'email') {
			avatarTemplate = avatarTpl({
				size: 'tiny',
				letter: account.email.substring(0, 1),
				account: avatar.account
			});
		} else {
			avatarTemplate = avatarTpl({
				size: 'tiny',
				letter: account.initials.substring(0, 1),
				account: avatar.account
			});
		}

		template = addedTpl({
			account,
			avatar: avatarTemplate
		});

		$('#collab-added-table').parents('table').addClass('--mt-20');
		$('#collab-added-table').append(template);
	},

	// Update the white label modal when a site is selected
	updateWhiteLabelModal(e, params) {
		// Remove loading class and typeahead results
		$('.typeahead__results').removeClass('loading open');

		// Update hidden field with ID of selected site
		$('[name="white_label_site[site_id]"]').val(params.site_id);

		// Remove "no site selected" error if one exists
		$('#js-selected-site-error').remove();

		const infoString = () => {
			if (params.domain.indexOf('agency-hosting') > -1) {
				return 'To keep Flywheel behind the scenes, we&rsquo;ll change your temporary flywheelsites.com domain to the one above.';
			}

			return 'To keep Flywheel behind the scenes, we&rsquo;ll give you the option to use mysites.io as a temporary domain.';
		};

		const selectedSiteTemplate = `<hr><img class="avatar-site --mb-20" src="${params.thumb}" width=117 height=64 alt="Site screenshot">
			<div class="kitchensink"><h2>${params.site_name}</h2>
			<p>${params.domain}</p>
			<p>${infoString()}</p></div>`;

		// Show the site selected
		$('#js-selected-site').html(selectedSiteTemplate).removeClass('hidden');
	}
};
